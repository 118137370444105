/* eslint-disable */
import { v4 } from 'uuid'
import api from '@/api/api'
import { validarCNPJ } from '@/utils/validacoes'
import { checkError } from '../utils/checkError'
import { formatterCPFCNPJ } from '@/utils/formatter'

const submit = async (template, planilha, error) => {
  const submitPlan = planilha.data.map(row => {
    const isPj = validarCNPJ(row['0.3'].value)
    const inscricao_estadual_antiga = (isPj) ? row['0.4'].value : null
    let data_ing = ''

    if (row['0.15'].value) {
      data_ing = row['0.15'].value ? new Date(row['0.15'].value) : ''
      data_ing = data_ing.setTime(data_ing.getTime() + data_ing.getTimezoneOffset() * 60 * 1000)
    }

    return {
      id: v4(),
      pessoaFis: !(isPj),
      codigo: row['0.0'].value || null,
      cpf: (isPj) ? null : formatterCPFCNPJ(row['0.3'].value) || null,
      nome: (isPj) ? '' : row['0.1'].value,
      rg: (isPj) ? '' : row['0.4'].value ? row['0.4'].value : '',
      cnpj: (isPj) ? formatterCPFCNPJ(row['0.3'].value) : null,
      razao_social: (isPj) ? row['0.1'].value : null,
      nome_fantasia: (isPj) ? row['0.2'].value : '',
      inscricao_estadual: (isPj) ? (!isNaN(row['0.4'].value?.replace(/\D/g, '')) ? row['0.4'].value?.replace(/\D/g, '') : '') : null,
      inscricao_estadual_antiga,
      email: row['0.5'].value || null,
      telefones: row['0.6'].value ? [row['0.6'].value] : 0,
      logradouro: row['0.7'].value || null,
      complemento: row['0.8'].value ? row['0.8'].value : '',
      numero: row['0.9'].value || '',
      bairro: row['0.10'].value || '',
      cep: row['0.11'].value || null,
      cidade: row['0.12'].value || null,
      estado: row['0.13'].value || null,
      pais: row['0.14'].value || null,
      status: row['0.16'].value.toLowerCase() === 'ativo' ? '1' : '0',
      data_ingresso: data_ing,
      regional: row['0.17'].value.toLowerCase(),
    }
  })
  return await api.cadastrarEntidade({ associados: submitPlan, erro: error }, 'associado/batch')
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.codigo, ...checkError(row, 'codigo') },
    '0.3': { value: row.cnpj || row.cpf, ...checkError(row, ['cpf', 'cnpj']) },
    '0.1': { value: row.nome || row.razao_social, ...checkError(row, ['nome', 'razao_social']) },
    '0.2': { value: row.nome_fantasia || row.nome, ...checkError(row, ['nome_fantasia', 'nome']) },
    '0.4': { value: row.inscricao_estadual_antiga || row.rg, ...checkError(row, ['inscricao_estadual', 'rg']) },
    '0.5': { value: row.email, ...checkError(row, 'email') },
    '0.6': { value: row.telefones[0], ...checkError(row, 'telefones') },
    '0.7': { value: row.logradouro, ...checkError(row, 'logradouro') },
    '0.8': { value: row.complemento, ...checkError(row, 'complemento') },
    '0.9': { value: row.numero, ...checkError(row, 'numero') },
    '0.10': { value: row.bairro, ...checkError(row, 'bairro') },
    '0.11': { value: row.cep, ...checkError(row, 'cep') },
    '0.12': { value: row.cidade, ...checkError(row, 'cidade') },
    '0.13': { value: row.estado, ...checkError(row, 'estado') },
    '0.14': { value: row.pais, ...checkError(row, 'pais') },
    '0.15': { value: row.data_ingresso, ...checkError(row, 'data_ingresso') },
    '0.16': { value: row.status, ...checkError(row, 'status') },
    '0.17': { value: row.status, ...checkError(row, 'regional') },
  }))
  return data_formatada
}

export default {
  submit,
  formatData,
}
