const internalCheckError = (row, field) => {
  let result = {
    err: false,
    msg: [''],
  }

  if (typeof field === 'string') {
    if (row.errors && row.errors.length > 0) {
      row.errors.forEach(item => {
        const errorObj = JSON.parse(item)
        if (field === errorObj.field) {
          result.err = true
          result.msg = [errorObj.msg]
        }
      })
    }
  } else {
    field.forEach(element => {
      const internalError = internalCheckError(row, element)
      if (internalError.err) result = internalError
    })
  }

  return result
}

export const checkError = internalCheckError
